import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import { StaticImage } from 'gatsby-plugin-image'

const PersonPage = () => (
  <Layout>
    <Seo
      title="Über mich"
      description="Ich möchte mich bei Ihnen vorstellen"
      keywords={[
        'Stefan',
        'Schopf',
        'Stefan Schopf',
        'Berater',
        'Pädagoge',
        'Psychologe',
      ]}
    />
    <article>
      <div className="fr w-100 w-third-l ma2 pl2-l">
        <StaticImage
          src={'../images/stefan.jpg'}
          alt="Stefan Schopf"
          imgClassName="mb2 mr2"
        />
      </div>
      <h1>Über mich</h1>
      <div className="w-100 w-two-thirds-l">
        <p>
          Ich bin 1951 geboren, habe eine Universitätsausbildung zum Grund- und
          Hauptschullehrer abgeschlossen (1. Lehramtsprüfung) und habe
          anschließend in Augsburg und Würzburg Pädagogik, Psychologie,
          Soziologie und Philosophie studiert.
        </p>
        <p>
          Als diplomierter Pädagoge (Dipl.-Päd. Univ.) habe ich in verschiedenen
          Bereichen der Jugendhilfe gearbeitet, bis ich in meiner beruflichen
          Wunschposition gelandet bin. Ich war über 25 Jahre lang als
          pädagogisch-psychologischer Berater und Familientherapeut an
          verschiedenen Beratungsstellen für Eltern, Kinder, Jugendliche und
          Familien tätig.
        </p>
        <p>Seit März 2018 biete ich freiberuflich Beratung an.</p>
        <p>
          Ich bin verheiratet, Vater dreier erwachsener Kinder und gehe meinen
          Neigungen des Musizierens und der Beschäftigung mit
          philosophisch-theologischen Fragen nach.
        </p>
      </div>
      <h2>Tätigkeitsspezifische Ausbildungen</h2>
      <p>
        1976 – 79 Intensives Studium der Tiefenpsychologie bei dem
        freudianischen Lehranalytiker und Pädagogikprofessor Günther Bittner in
        Würzburg
      </p>
      <p>
        1983 – 86 Wöchentliche Fallbesprechungen in der multiprofessionell
        zusammengesetzten Balintgruppe des Psychoanalytikers Graf Schlieffen in
        München
      </p>
      <p>
        1990 – 93 Abgeschlossene Weiterbildung in psychoanalytischer
        Familientherapie und Krisenintervention an der Akademie für
        Psychoanalyse und Psychotherapie in München
      </p>
      <p>
        2000 -2002 Abgeschlossene 52-tägige Ausbildung durch das Institut für
        pädagogische Diagnostik IPD, Siegburg, in hermeneutischer
        Fallrekonstruktion. Dieses Verfahren der Biografieanalyse basiert auf
        der von dem Frankfurter Soziologieprofessor Ulrich Oevermann
        entwickelten Objektiven Hermeneutik.
      </p>
      <h2>Berufsbegleitende Fortbildung</h2>
      <p>
        Mein berufliches Beratungsspektrum reichte von Erziehungs- und
        Beziehungsthemen, innerfamiliärer Kommunikation, Bewältigung
        krisenhafter Familienphasen – insbesondere Trennung und Scheidung – bis
        hin zu spezifischen Problematiken wie Gewalt, Süchte, psychische
        Krankheit, sexueller Missbrauch und Suizidalität. Das erforderte eine
        Fülle von Kompetenzen, die ich mir über Teamintervision, Supervision und
        diverse Fortbildungen aneignete.
      </p>
      <h2>Schwerpunkte der Weiterbildungen</h2>
      <ul>
        <li>Anamnese und Biografie-Analyse</li>
        <li>Alters- und problemspezifische Diagnostik</li>
        <li>Methodiken von Beratung und Therapie</li>
        <li>Trennungs- und Scheidungsberatung</li>
        <li>
          Themenspezifische Fortbildungen wie Kindeswohlgefährdung,
          Fortsetzungsfamilien und interkulturelle Kommunikation
        </li>
      </ul>
    </article>
  </Layout>
)

export default PersonPage
